.button__outline{
    height: 40px;
    /* width: 180px; */

    padding-left: 24px;
    padding-right: 24px;
    border-radius:12px;
    width: 180px;
    border: 2px solid black;
    outline: none;
    background:none;
    border-width: 1;
    color:white;
    font-weight:600;
    font-size: 16px;

    border-color: white;

}


.button__blank{
    /* height: 10px; */

    border: none;
    color:white;
    font-weight:500;
    font-size: 16px;
    background: none;
    border-color: none;
    align-items: center;
    justify-content: center;
    display: flex;
}




.button__navCell {
    height: 40px;
    /* width: 180px; */
    margin: 0;
    padding-left: 24px;
    padding-right: 24px;
    border-radius:12px;
    width: 180px;
    font-weight:600;
    font-size: 16px;
    border-radius: 12px;
    border: none;
    outline: none;
    background:#01AAFD;

    color:white;

    /* letter-spacing: 1px; */
    /* text-transform: uppercase; */
    cursor: pointer;
    position: relative;

}

.button__download:hover{
    background: linear-gradient(90deg,#1DF0A9,#E289E5,#E7C6CA,#827CF3,#9FB7F1,#1DF0A9);
    background-size: 400%;
}

.button__download:before{
    content: '';
    position: absolute;
    background: inherit;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    border-radius: 50px;
    filter: blur(20px);
    opacity: 0;
    transition: opacity 0.5s;
}

.button__download:hover:before{

    opacity: 1;
    z-index: -1;
}
.button__download:hover{
    z-index: 1;
    animation: glow 8s linear infinite;
}

.mileStoneContainer{
    width: 100%;
    height: 100%;
    object-fit: contain;

}

.imageResizeToContain{
    width: 100%;
    height: 100%;
    object-fit:contain;

}

.textfont {
    font-family: 'Noto Sans Mono', monospace;
    color: #283A96
 
  }