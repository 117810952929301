.button__text{
    /* height: 10px; */

    border: none;
    color:white;
    font-weight:'bold';
    font-size: 16px;
    /* background: none; */
    border-color: none;
    align-items: center;
    justify-content: center;
    display: flex;
    /* width: 100px; */;
    align-items: flex-start;
 

}


